<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 贡献值标签管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container" v-loading="loading">
      <div class="handle-box">
        <el-select v-model="query.ProjectName" placeholder="请选择选择项目" class="handle-select mr10" @change="handleSearch"
                   size="small">
          <el-option :key="index" :label="item.Title" :value="item.Title"
                     v-for="(item,index) in query.ProjecData"></el-option>
        </el-select>
        <div class="ToNumber">项目总额度：<b>{{ query.ToNumber }}</b> QB <span></span>项目已使用额度：<b>{{ query.SetNumber }}</b> QB
          <span></span>项目未使用额度：<b>{{ query.NoNumber }}</b> QB
        </div>
      </div>

      <el-table
          :data="tableData"
          border
          size="small"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Title" label="社区项目昵称"></el-table-column>
        <el-table-column prop="ComName" label="委员会昵称"></el-table-column>
        <el-table-column prop="SetNumber" label="已使用额度"></el-table-column>
        <el-table-column prop="NoNumber" label="未使用额度"></el-table-column>
        <el-table-column prop="TolNumber" label="总额度"></el-table-column>
        <el-table-column prop="NewTime" label="添加时间" width="140"></el-table-column>
        <el-table-column prop="UpTime" label="额度更新时间" width="140"></el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-button @click="openCommitteeAdmin(scope.row.Title,scope.row.ComName)">标签管理</el-button>
            <el-button @click="openAmount(scope.row)">额度管理</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <TypeAdmin ref="TypeAdmin"></TypeAdmin>
    <AmountAdmin ref="AmountAdmin"></AmountAdmin>
  </div>
</template>

<script>

import TypeAdmin from './com/TypeAdmin'
import AmountAdmin from './com/AmountAdmin'
import {FindProjec, FindCommittee} from "../../api/ProjectAdmin/Type";


export default {
  data() {
    return {
      loading:false,
      query: {
        ProjectName: '',
        ProjecData: [],
        ToNumber: 0,
        NoNumber: 0,
        SetNumbe: 0,
      },
      App_id: '',
      tableData: [],
    };
  },
  created() {
    this.findProjec()
  },
  methods: {
    openAmount(row) {
      let App_id = this.App_id
      this.$refs.AmountAdmin.open(App_id, row)
    },
    openCommitteeAdmin(Title, ComName) {
      let App_id = this.App_id
      this.$refs.TypeAdmin.open(App_id, Title, ComName)
    },
    handladd() {
      this.$refs.CommitteeAdd.open(this.App_id)
    },
    handleSearch() {
      let ProjecData = this.query.ProjecData
      for (let item in ProjecData) {
        if (this.query.ProjectName == ProjecData[item].Title) {
          let App_id = ProjecData[item].App_id
          this.App_id = App_id
          this.FindCommittee()
          break;
        }
      }
    },
    findProjec() {
      this.loading=true
      FindProjec().then(res => {
       this.loading=false
        let ProjecData = res.sum
        this.query.ProjecData = ProjecData
        if (ProjecData.length >= 1) {
          let Title = ProjecData[0].Title
          this.query.ProjectName = Title
          this.handleSearch()
        }
      })
    },
    FindCommittee() {
      let App_id = this.App_id
      FindCommittee({App_id}).then(res => {
        this.tableData = res.sum
        let Tol = res.Tol
        let NoNumber = Tol.NoNumber
        let SetNumber = Tol.SetNumber
        let ToNumber = Tol.ToNumber
        this.query.NoNumber = NoNumber
        this.query.SetNumber = SetNumber
        this.query.ToNumber = ToNumber

      })
    },
  },
  components: {
    TypeAdmin,
    AmountAdmin
  }
};
</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 200px;
  margin-right: 20px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.ToNumber {
  float: right;
  margin-right: 10px;
  line-height: 32px;
  padding: 5px 10px;
  background-color: #e5e6e1;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;

  span {
    margin-left: 20px;
  }
}
</style>
