<template>

  <el-dialog title="标签管理" v-model="view" width="1000px">
    <div class="title">{{ Titletext }}</div>
    <el-button type="primary" icon="el-icon-plus" @click="handladd" class="add">添加标签</el-button>
    <el-table
        :data="tableData"
        border
        class="table"
        header-cell-class-name="table-header"
        height="400"
    >
      <el-table-column prop="Name" label="分类昵称"></el-table-column>
      <el-table-column prop="Remarks" label="备注"></el-table-column>
      <el-table-column prop="Switch" label="是否可见">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.Switch=='1'">可见</el-tag>
          <el-tag type="danger" v-else>不可见</el-tag>
          <el-button type="text" class="Switch" v-if="scope.row.Switch=='0'"
                     @click="switchType('不可见',scope.row.id,scope.row.Name)">设置可见
          </el-button>
          <el-button type="text" class="Switch"  @click="switchType('可见',scope.row.id,scope.row.Name)" v-else>设置不可见
          </el-button>
        </template>

      </el-table-column>
      <el-table-column prop="Time" label="添加时间"></el-table-column>

      <el-table-column>
        <template #default="scope">
          <el-button type="text" @click="openEdit(scope.row.id,scope.row.Name,scope.row.Remarks)">修改备注</el-button>
          <el-button type="text" class="red" @click="handremove(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
                <span class="dialog-footer">
                    <el-button @click="view = false">关闭</el-button>
                </span>
    </template>
  </el-dialog>
  <TypeEdit ref="TypeEdit"></TypeEdit>
  <TypeAdd ref="TypeAdd"></TypeAdd>
</template>

<script>

import {RemoveType, FindType, SwitchType} from '../../../api/ProjectAdmin/Type'

import TypeAdd from '../com/TypeAdd'
import TypeEdit from '../com/TypeEdit'


export default {
  data() {
    return {
      Titletext: '',
      ComName: '',
      view: false,
      query: {
        App_id: '',
        Title: '',
        ComName: '',
      },
      tableData: []
    }
  },
  methods: {

    switchType(text, id, Name) {
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      let App_id = this.query.App_id

      this.$confirm("是否确认设置该标签" + text + "吗？", "提示", {
        type: "warning"
      }).then(() => {
        SwitchType({ id, Name,Admin_id,App_id}).then(res => {
          if (res.code == 0) {
            this.getdata()
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        })
      })
    },
    handremove(id) {
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      let App_id = this.query.App_id
      this.$confirm("是否确认删除该标签吗？", "提示", {
        type: "warning"
      }).then(() => {
        RemoveType({id, Admin_id, App_id}).then(res => {
          if (res.code == 0) {
            this.getdata()
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        })
      })
    },
    handladd() {
      let App_id = this.query.App_id
      let ComName = this.query.ComName
      this.$refs.TypeAdd.open(App_id, ComName)
    },
    getdata() {
      let App_id = this.query.App_id
      let ComName = this.query.ComName
      FindType({App_id, ComName}).then(res => {
        let tableData = res.sum
        this.tableData = tableData
      })
    },
    openEdit(id, Name, Remarks) {
      let App_id = this.query.App_id
      this.$refs.TypeEdit.open(App_id, id, Name, Remarks)
    },
    open(App_id, Title, ComName) {
      this.view = true
      this.tableData = []
      let Titletext = '社区项目：' + Title + '   委员会：' + ComName
      this.Titletext = Titletext
      this.query.App_id = App_id
      this.query.Title = Title
      this.query.ComName = ComName
      this.getdata()
    }
  },
  components: {
    TypeAdd,
    TypeEdit
  }
}
</script>

<style scoped>
.title {
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 16px;
}

.add {
  margin-bottom: 20px;
}

.red {
  color: red;
}

.Switch{
  margin-left: 20px;
}
</style>