<template>
  <el-dialog title="额度管理" v-model="view" width="1000px">
    <div class="title">{{ Titletext }}</div>
    <el-button type="primary" icon="el-icon-plus" @click="handladd" class="add">新增额度</el-button>
    <el-button type="primary" icon="el-icon-minus" @click="handlreduce" class="add">减少额度</el-button>
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="bg-purple">未使用额度：<b>{{ data.NoNumber }} QB</b></div>
      </el-col>
      <el-col :span="8">
        <div class="bg-purple green">已使用额度：<b>{{ data.SetNumber }} QB</b></div>
      </el-col>
      <el-col :span="8">
        <div class="bg-purple yell">总额度：<b>{{ data.TolNumber }} QB</b></div>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="view = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {QuotaAdd, QuotaFind, QuotaReduce} from '../../../api/ProjectAdmin/Type'

export default {
  data() {
    return {
      Titletext: '',
      view: false,
      query: {
        App_id: '',
      },
      data: {}
    }
  },
  methods: {
    handlreduce(){
      this.$prompt('请输入减少额度', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '额度格式不正确'
      }).then(({value}) => {
        let query = this.query
        let App_id = query.App_id
        let ComName = query.ComName
        let Number = value
        this.$parent.openLoading()
        QuotaReduce({App_id, ComName, Number}).then((res) => {
          this.$parent.closeLoading()
          if (res.code == 0) {
            this.getData();
            this.$message.success(res.message);
            this.$parent.FindCommittee();
          } else {
            this.$message.error(res.message);
          }
        })
      })
    },
    open(App_id, row) {
      this.view = true
      this.query = {...row}
      this.query.App_id = App_id
      this.getData()
    },
    getData() {
      let query = this.query
      let App_id = query.App_id
      let ComName = query.ComName
      QuotaFind({App_id, ComName}).then(res => {
        let data = res.data
        this.data = data
      })

    },
    handladd() {
      this.$prompt('请输入增加额度', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '额度格式不正确'
      }).then(({value}) => {
        let query = this.query
        let App_id = query.App_id
        let ComName = query.ComName
        let Number = value
        this.$parent.openLoading()
        QuotaAdd({App_id, ComName, Number}).then((res) => {
          this.$parent.closeLoading()
          if (res.code == 0) {
            this.getData();
            this.$message.success(res.message);
            this.$parent.FindCommittee();
          } else {
            this.$message.error(res.message);
          }
        })
      })
    }
  }

}
</script>

<style scoped lang="scss">
.add {
  margin-bottom: 20px;
}

.bg-purple {
  background-color: #e5e6e1;
  width: 300px;
  height: 40px;
  line-height: 40px;
  text-indent: 20px;
  border-radius: 5px;
}

.green {
  background-color: #00a854;
  color: #ffffff;
}

.yell {
  background-color: #e6790c;
  color: #ffffff;
}
</style>