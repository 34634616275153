import request from '../../utils/request';

/**
 * 贡献值标签管理
 * @param query
 * @returns {AxiosPromise}
 */

//查询项目总数据
export const FindProjec = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Type/TolObject/find',
        method: 'get',
        params: query
    });
};


//查询委员会信息
export const FindCommittee = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Type/Committee/find',
        method: 'get',
        params: query
    });
};


//查询委员会标签
export const FindType = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Type/find',
        method: 'get',
        params: query
    });
};

//添加标签
export const AddType = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Type/add',
        method: 'post',
        data: query
    });
};

//编辑标签
export const EditType = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Type/edit',
        method: 'post',
        data: query
    });
};

//删除标签
export const RemoveType = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Type/remove',
        method: 'post',
        data: query
    });
};

//更新设置标签可见（非系统）
export const SwitchType = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Type/Switch/edit',
        method: 'post',
        data: query
    });
};





//查询委员会额度
export const QuotaFind = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Type/quota/find',
        method: 'post',
        data: query
    });
};


//新增委员会额度
export const QuotaAdd = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Type/quota/add',
        method: 'post',
        data: query
    });
};

//减少委员会额度
export const QuotaReduce = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Type/quota/reduce',
        method: 'post',
        data: query
    });
};
